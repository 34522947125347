import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import { api } from './api';
  
const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
});

const initialState = { count: 0 }

const createStore = () => configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        SerializableStateInvariantMiddleware: false,
        serializableCheck: false,
      }).concat(api.middleware),
})

export default createStore