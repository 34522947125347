import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { navigate } from 'gatsby';
import { isTokenExpired, isTokenRol } from '../utils';
import { UserRoles } from '../enum/user-roles.enum';
const baseUrl = process.env.GATSBY_BACKEND_API_URL;

export const api = createApi({
    reducerPath: 'apiQClothier',
    baseQuery: fetchBaseQuery({
      baseUrl,
      prepareHeaders: (headers, { }) => {
        console.log('Preparing the headers');
        // If we have a token set in state, let's assume that we should be passing it.
        const path = window.location.pathname;
        if (path !== '/login/') {
          const token = localStorage.getItem('token');
          if ((token && isTokenRol(token, UserRoles.CUSTOMER) && !path.startsWith('/main/c')) || 
                (token && isTokenRol(token, UserRoles.REFERRAL) && !path.startsWith('/gift'))) {
            localStorage.removeItem('token');
            navigate('/login')
          }

          if (token && !isTokenExpired(token)) {
            headers.set('authorization', `Bearer ${token}`);
          } else if (!(path.startsWith('/main/c') || path.startsWith('/gift/'))) {
            localStorage.setItem('return_page', window.location.pathname);
            navigate('/login')
          }
        }
        headers.set('Content-Type', 'application/json');
        return headers;
      },
    }),
    tagTypes: [],
    endpoints: () => ({})
})