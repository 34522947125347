exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-customers-tsx": () => import("./../../../src/pages/customers.tsx" /* webpackChunkName: "component---src-pages-customers-tsx" */),
  "component---src-pages-gift-coupon-code-tsx": () => import("./../../../src/pages/gift/[couponCode].tsx" /* webpackChunkName: "component---src-pages-gift-coupon-code-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-main-customer-id-tsx": () => import("./../../../src/pages/main/[customerId].tsx" /* webpackChunkName: "component---src-pages-main-customer-id-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-sales-store-id-tsx": () => import("./../../../src/pages/sales/[storeId].tsx" /* webpackChunkName: "component---src-pages-sales-store-id-tsx" */),
  "component---src-pages-stores-tsx": () => import("./../../../src/pages/stores.tsx" /* webpackChunkName: "component---src-pages-stores-tsx" */),
  "component---src-pages-users-tsx": () => import("./../../../src/pages/users.tsx" /* webpackChunkName: "component---src-pages-users-tsx" */)
}

