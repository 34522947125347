
import jwtDecode from 'jwt-decode';

export const getTokenData = (jwt: string | null): any => {
    if (!jwt)
        return null;
    return jwtDecode(jwt);
}

export const getTokenExp = (jwt: string | null) => {
    const jwtInfo: any = getTokenData(jwt);
    if (!jwtInfo)
        return true;
    const expirationDate = new Date(0);
    expirationDate.setUTCSeconds(jwtInfo.exp);
    return expirationDate;
}

export const isTokenRol = (jwt: string, rol: string): boolean => {
    const data = getTokenData(jwt)
    return data.roles?.includes(rol);
}

export const isTokenExpired = (jwt: string | null) => {
    const jwtExpiration = getTokenExp(jwt);
    if (!jwtExpiration)
        return true;

    return jwtExpiration < new Date();
}

export const isAuthenticated = (jwt: string) => {
    return jwt != null && !isTokenExpired(jwt);
}

export const formatPhoneNumber = (date: string): string => {
    if (date == null)
        return '';
    if (!date.startsWith('+1') && date.length == 10) {
        date = '+1' + date;   
    }
    if (date.startsWith('+1')) {
        const areaCode = date.substring(2, 5);
        const first = date.substring(5, 8);
        const second = date.substring(8);
        return `(${areaCode}) ${first}-${second}`;
    } else if (date.startsWith('+502')) {
        const countryCode = date.substring(0, 4);
        const first = date.substring(4, 8);
        const second = date.substring(8);
        return `${countryCode} ${first}-${second}`;
    } else {
        return date;
    }
}

export const transformStatus = (status: boolean) => (status) ? 'Enabled' : 'Disabled'
